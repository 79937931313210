import React, { useState, useRef } from 'react'
// import { Script } from 'gatsby-script'
// import { MailIcon, PhoneIcon, AtSymbolIcon } from '@heroicons/react/outline'

const Form = () => {
  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbxrfLfVf_pIkYIelR3M9Bs6BUs2FSFv9LPEYRSxzpo9ci-tZugidN12mBZXiyhUvZsa/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    fetch(scriptUrl, {
      method: 'POST',
      body: new FormData(formRef.current),

    }).then(res => {
      //console.log("SUCCESSFULLY SUBMITTED")
      window.location.assign('/contact-validation/')
      setLoading(false)
    })
      .catch(err => console.log(err))
  }

  return (
    <div className="relative bg-white rounded-xl">
      <div className="bg-white py-4 px-4 sm:px-6 lg:col-span-3 lg:py-6 lg:px-6 xl:pl-6 rounded-xl">
        <div className="max-w-lg mx-auto lg:max-w-none">
          <form
            className="gap-y-6"
            name="google-sheet"
            ref={formRef} onSubmit={handleSubmit}
          >
            <div className="pb-4">
              <h3 className="text-2xl font-bold text-center pb-4">
                Inscrivez-vous
              </h3>
              <label htmlFor="nom" className="sr-only">
                Entreprise
              </label>
              <input
                type="text"
                name="Entreprise"
                id="entreprise"
                autoComplete="name"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-amber-600 focus:border-amber-600 border-gray-300 rounded-md"
                placeholder="Entreprise"
                required
              />
            </div>
            <div className="pb-4">
              <label htmlFor="nom" className="sr-only">
                Nom
              </label>
              <input
                type="text"
                name="Nom"
                id="nom"
                autoComplete="name"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-amber-600 focus:border-amber-600 border-gray-300 rounded-md"
                placeholder="Nom"
                required
              />
            </div>
            <div className="pb-4">
              <label htmlFor="nom" className="sr-only">
                Prénom
              </label>
              <input
                type="text"
                name="Prénom"
                id="prenom"
                autoComplete="name"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-amber-600 focus:border-amber-600 border-gray-300 rounded-md"
                placeholder="Prénom"
                required
              />
            </div>
            <div className="pb-4">
              <label htmlFor="telephone" className="sr-only">
                Téléphone portable
              </label>
              <input
                type="text"
                name="Téléphone"
                id="telephone"
                autoComplete="tel"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-amber-600 focus:border-amber-600 border-gray-300 rounded-md"
                placeholder="Téléphone portable"
                required
              />
            </div>
            <div className="pb-4">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                id="email"
                name="Email"
                type="email"
                autoComplete="email"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-amber-600 focus:border-amber-600 border-gray-300 rounded-md"
                placeholder="Email"
                required
              />
            </div>

            <div className="pb-4">
              <label htmlFor="offre" className="sr-only">
                Offre
              </label>
              <select
                name="Offre"
                id="offre"
                autoComplete="offre"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-amber-600 focus:border-amber-600 border-gray-300 rounded-md"
                required
              >
                <option>Offre Découverte</option>
                <option>Offre Koudepouce Visibilité</option>
                <option>Offre Koudepouce Visibilité Plus</option>
              </select>
            </div>
            <div className="hidden">
              <label htmlFor="tellepro" className="sr-only">
                Téllépro
              </label>
              <input
                type="text"
                name="tellepro"
                id="tellepro"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                placeholder="Téllépro"
              />

            </div>
            <div className="pb-4">
              <input
                type="checkbox"
                id="cgu"
                name="CGU"
                className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-amber-600 focus:border-amber-600 border-gray-300 rounded-md"
                required
              />J'accepte les <a href="/legal/cgu/" className="text-amber-600" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation</a>
            </div>
            <div className="pb-4">
              <button
                type="submit"
                className="inline-flex items-center mr-6 rounded-md ring-1 ring-amber-600 bg-amber-600 px-3.5 py-2.5 text-base font-semibold text-gray-100 shadow-sm hover:bg-amber-00 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                {loading ? "Envoi en cours ..." : "Envoyez"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form
